<template>
    <div class="follow-button">
        <button :class="isFollowing ? 'btn btn-selected': 'btn'" @click="followEntity">
            {{ isFollowing ? "Following" : "Follow" }}
        </button>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "FollowEntityButton",
    props: {
        useApiV2: {
            type: Boolean,
            default: false
        },
        entity: {
            type: String,
            required: true
        },
        isFollowing: {
            type: Number,
            required: true
        },
        entityId: {
            type: Number,
            required: true
        },
        entityName: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        })
    },
    methods: {
        followEntity() {
            if (!this.isLoggedIn) {
                this.$modal.show("auth-modal", { allowClose: true, showSignIn: true });
            } else {
                const axiosVersion = this.useApiV2 ? axiosV2 : axios;
                const isFollowing = !this.isFollowing;
                this.$emit("toggled-follow", isFollowing);
                this.$amplitude.logEvent(isFollowing ? "Follow" : "Unfollow", {
                    entity_id: this.entityId,
                    event_name: isFollowing ? "Follow" : "Unfollow",
                    title: this.entityName,
                    type: this.entity
                });
                axiosVersion.post(`/${this.entity}/${this.entityId}/follow`).then((response) => {
                    const isFollowingResponse = Boolean(response.data["is_following"]);
                    if (isFollowing != isFollowingResponse) {
                        this.$emit("toggled-follow", isFollowingResponse);
                    }
                }).catch((error) => {
                    console.log(error.response.data.errors.message);
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.btn {
    width: 88px;
    height: 32px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    background-color: rgba(255, 255, 255, 0.8);
    color: rgb(40,40,41);
    padding: 8px 0px;

    &:focus {
        box-shadow: none;
    }

    &:hover {
        color: rgb(40,40,41);
    }
}

.btn-selected {
    width: 88px;
    height: 32px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    background-color: rgba(255, 255, 255, 0.10);
    color: white;
    padding: 8px 0px;

    &:hover {
        color: white;
    }
}
</style>
